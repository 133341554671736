import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

export default props => (
  <Layout {...props}>
    <Seo title='Cookie policy' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header="Cookie policy"/>
        <Divider />
        <h2 id="cookie-policy">COOKIE POLICY</h2>
        <p>Gli applicativi software utilizzati possono contenere la tecnologia “cookie”. I cookie hanno principalmente la funzione di agevolare la navigazione da parte dell’utente. I cookie potranno fornire informazioni sulla navigazione all’interno del Sito e permettere il funzionamento di alcuni servizi che richiedono l’identificazione del percorso dell’utente attraverso diverse pagine del Sito. Per qualsiasi accesso al portale indipendentemente dalla presenza di un cookie, vengono registrati il tipo di browser il sistema operativo (es. Microsoft, Apple, Android, Linux, ecc…), l’Host e l’URL di provenienza del visitatore, oltre ai dati sulla pagina richiesta. L’utente ha comunque modo di impostare il proprio browser in modo da essere informato quando ricevete un cookie e decidere in questo modo di eliminarlo. Ulteriori informazioni sui cookie sono reperibili sui siti web dei forniti di browser.</p>
        <h3 id="cosa-sono-i-cookie-">Cosa sono i cookie?</h3>
        <p>I cookie sono stringhe di testo di piccole dimensioni conservate nel computer dell’utente, quando si visitano determinate pagine su internet. Nella maggior parte dei browser i cookie sono abilitati, in calce le informazioni necessarie per modificare le impostazioni dei cookie sul browser. I cookie non sono dannosi per il device. Nei cookie che generiamo, non conserviamo informazioni identificative personali, ma usiamo informazioni per migliorare la permanenza sul sito. Per esempio sono utili per identificare e risolvere errori.</p>
        <p>I cookie possono svolgere diverse funzioni, come ad esempio di navigare fra le varie pagine in modo efficiente e in generale possono migliorare la permanenza dell’utente. Il titolare si riserva il diritto di utilizzare i cookie, con il consenso dell’utente ove la legge o i regolamenti applicabili così stabiliscano, per facilitare la navigazione su questo sito e personalizzare le informazioni che appariranno. Il titolare si riserva inoltre il diritto di usare sistemi simili per raccogliere informazioni sugli utenti del sito, quali ad esempio tipo di browser internet e sistema operativo utilizzato, per finalità statistiche o di sicurezza</p>
        <h3 id="tipi-di-cookie">Tipi di cookie</h3>
        <p>I cookie poi si distinguono in “di sessione” e “persistenti”, i primi una volta scaricati vengono poi eliminati alla chiusura del Browser, i secondi invece vengono memorizzati fino alla loro scadenza o cancellazione. I cookie di sessione utilizzati hanno lo scopo di velocizzare l’analisi del traffico su Internet e facilitare agli utenti l’accesso ai servizi offerti dal sito. I cookie di sessione vengono principalmente utilizzati in fase di autenticazione, autorizzazione e navigazione nei servizi ai quali si accede tramite una registrazione. La maggior parte dei browser è impostata per accettare i cookie. Se non si desidera accettare i cookie, è possibile impostare il computer affinché li rifiuti oppure visualizzi un avviso quando essi vengono archiviati. Se l’interessato rifiuta l’uso dei cookie alcune funzionalità del sito potrebbero non funzionare correttamente ed alcuni dei servizi non essere disponibili. L’uso di c.d. cookie di sessione è strettamente limitato alla trasmissione di identificativi di sessione, necessari per consentire l’esplorazione sicura ed efficiente del sito. Tali dati hanno natura tecnica, validità temporanea e non sono raccolti allo scopo di identificare gli utenti ma, per via del loro funzionamento e in combinazione con altri dati detenuti da soggetti terzi (ad esempio il fornitore di connessione Internet dell’interessato), potrebbero permetterne l’identificazione. I Cookie di prima parte sono leggibili al solo dominio che li ha creati.</p>
        <h3 id="che-cookie-utilizziamo-">Che cookie utilizziamo:</h3>
        <ul>
        <li><p><strong>Cookie tecnici di navigazione</strong>
        Sono cookie che garantiscono la normale navigazione e fruizione del sito web e che permettono il collegamento tra il server e il browser dell’utente. Sono cookie indispensabili alla navigazione (per esempio gli identificativi di sessione) che consentono di utilizzare le principali funzionalità del sito e di proteggere la connessione. Senza questi cookie, non potrebbero essere possibile utilizzare normalmente il sito.</p>
        </li>
        <li><p><strong>Cookie funzionali</strong>
        Sono cookie memorizzati nel computer o altro dispositivo che, in base alla richiesta dell’utente, registrano le scelte dello stesso, per permettergli di ricordargliele al fine di ottimizzare e fornire una navigazione migliorativa e personalizzata all’interno del servizio o dell’accesso al presente sito. (es registrazione in aree riservate, memorizzazione dei prodotti nel carrello per avere la possibilità di ritrovarseli nella successiva sessione, salvare la lingua selezionata, visualizzazione di un video o la possibilità di commentare il blog ecc.). I cookie funzionali non sono indispensabili al funzionamento del sito, ma migliorano la qualità e l’esperienza della navigazione</p>
        </li>
        <li><p><strong>Cookie Analitici</strong>
        Sono cookie che raccolgono informazioni su come l’utente utilizza il sito web, come ad esempio quali pagine web vengono visitate più spesso. Il presente sito utilizza i cookies di terze parti “Google Analytcs”. Google Analitycs è un sito di statistiche, ad esempio calcola il numero dei visitatori, che tipo di tecnologia stanno utilizzando (per esempio Mac o Windows ), quanto tempo trascorrono sul sito… Tutti i dati raccolti sono anonimi ed in forma aggregata pertanto non profilanti. Le informazioni generate dal cookie sull’utilizzo del sito web da parte dell’utente (compreso l’indirizzo IP) viene trasmesso, e depositato presso i server di Google.
        Indirizzi utili per comprendere al meglio la privacy policy di Google Analytics: – <a href="https://www.google.com/analytics/terms/it.html">http://www.google.com/analytics/terms/it.html</a><a href="https://www.google.com/privacypolicy.html">http://www.google.com/privacypolicy.html</a>
        I cookie analitici non sono indispensabili al funzionamento del sito.</p>
        </li>
        <li><p><strong>Cookie relativi a widget di Social Network</strong>
        Widgets: Si tratta di tutti quei componenti di una interfaccia utente di un programma, che ha lo scopo di facilitare l’utente nell’interazione con il programma stesso. Durante l’accesso potrebbero installarsi cookie che permettono all’account social dell’utente di interagire con il presente sito e non sono indispensabili per il funzionamento dello stesso. L’utilizzo più comune è quello finalizzato alla condivisione dei contenuti dei social network. La presenza dei plugin comporta la trasmissione di cookie da e verso tutti i siti gestiti da terze parti. La gestione delle informazioni raccolte da “terze parti” è disciplinata dalle relative informative cui si prega di fare riferimento.</p>
        </li>
        <li><p><strong>Cookie relativi a Social Buttons</strong>
        I Social buttons sono quei particolari “pulsanti” presenti sul sito che raffigurano le icone di social network (esempio, Facebook ) e consentono agli utenti che stanno navigando di interagire con un “click” direttamente con i social network.I social buttons utilizzati dal sito sono dei link che rinviano agli account del Titolare sui social network raffigurati. Tramite l’utilizzo di tali pulsanti non sono pertanto installati cookie di terze parti. Il sito non condivide alcuna informazione di navigazione o dato dell’utente acquisiti attraverso il proprio sito con i social network accessibili grazie ai Social buttons.</p>
        </li>
        </ul>
        <h3 id="come-posso-disabilitare-i-cookie-">Come posso disabilitare i cookie?</h3>
        <p>Avvertenza: in relazione alla disattivazione da parte dell’utente di tutte le tipologie di cookie (tecnici compresi) si precisa che alcune funzionalità del sito potrebbero essere ridotte o non disponibili.
        La maggior parte dei browser accettano i cookie automaticamente, ma potete anche scegliere di non accettarli. Se non desideri che il tuo computer riceva e memorizzi cookie, puoi modificare le impostazioni di sicurezza del browser (Internet Explorer, Google Chrome, Mozilla Firefox, Safari ecc…). Esistono diversi modi per gestire i cookie e altre tecnologie di tracciabilità. Modificando le impostazioni del browser, si può accettare o rifiutare i cookie o decidere di ricevere un messaggio di avviso prima di accettare un cookie dai siti Web visitati. Si possono eliminare tutti i cookie installati nella cartella dei cookie del suo browser. Ciascun browser presenta procedure diverse per la gestione delle impostazioni.</p>
        <p>I cookie possono essere disattivati direttamente dal proprio web browser. Per informazioni più dettagliate in riferimento alle opzioni di disattivazione dei cookie ecco l’elenco dei link per la configurazione dei browser più diffusi.</p>
        <ul>
        <li>Come disattivare i cookie in <a href="https://support.mozilla.org/it/kb/Attivare e disattivare i cookie">Firefox</a></li>
        <li>Come disattivare i cookie in <a href="https://support.google.com/chrome/answer/95647?hl=it">Chrome</a></li>
        <li>Come disattivare i cookie in <a href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11">Internet Explorer</a></li>
        <li>Come disattivare i cookie in <a href="https://support.apple.com/kb/HT1677?viewlocale=it_IT&amp;locale=it_IT">Safari</a></li>
        <li>Come disattivare i cookie in <a href="http://help.opera.com/Windows/10.00/it/cookies.html">Opera</a></li>
        </ul>
        <p>Se non si utilizza nessuno dei browser sopra elencati, seleziona “cookie” nella relativa sezione della guida per scoprire dove si trova la tua cartella dei cookie.</p>
        <h3 id="consenso">Consenso</h3>
        <p>Il consenso viene liberamente espresso dall’utente proseguendo con la navigazione, dopo aver preso visione dell’informativa breve. Si fa presente peraltro che in qualsiasi momento può essere revocato il permesso e si può procedere con la disabilitazione dei cookie come sopra descritto.</p>
        <h3 id="altri-link">Altri link</h3>
        <p>Per fornire un miglior servizio all’utente, questo Sito può contenere rinvii ad altri siti web (“Links”). Il Titolare  declina ogni responsabilità in ordine ai contenuti ed ai materiali accessibili presso tal siti o comunque ottenibili per loro tramite. Qualsiasi informazione inerente i siti richiamati dai link dovranno essere rivolti unicamente agli amministratori dei siti in questione.</p>
        <h2 id="modifiche-all-informativa-sull-uso-dei-cookie">Modifiche all’informativa sull’uso dei cookie</h2>
        <p>Affinché sia sempre aggiornata, l’informativa sull’uso dei cookie viene periodicamente rivisitata. L’ultimo aggiornamento alla presente informativa sull’uso dei cookie risale al  13 luglio 2018</p>
        <h3 id="informazioni-riguardanti-la-risoluzione-online-delle-dispute-ai-sensi-dell-art-14-par-1-dell-odr-online-dispute-resolution-regulation-">Informazioni riguardanti la risoluzione online delle dispute ai sensi dell’Art. 14 Par. 1 dell’ODR (Online Dispute Resolution Regulation):</h3>
        <p>La Commissione Europea assegna ai consumatori la possibilità di risolvere online le dispute, ai sensi dell’Art. 14 Par. 1 dell’Online Dispute Resolution (ODR) su una delle sue piattaforme. La piattaforma (<a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>) serve come un luogo in cui i consumatori possono cercare di raggiungere accordi stragiudiziali sulle controversie derivanti da acquisti on-line e da contratti di servizio.</p>
     </Main>
    </Stack>
  </Layout>
)
